<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.profitLoss') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="productModel" :options="products" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.product') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <SelectButton v-model="reportTypeModel" :options="reportTypes" optionLabel="name" />
                    </div>
                    <div class="p-col-6 p-md-3 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <div class="p-grid">
                        <div class="p-col-6 p-md-3">
                            <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div class="p-d-flex p-ai-center muted-text">
                                    <h6 class="p-m-0">{{ $t('reports.incomes') }}</h6>
                                </div>
                                <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div class="p-d-flex p-flex-column">
                                        <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.Incomes) + ' ' + $t('general.currency') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6 p-md-3">
                            <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div class="p-d-flex p-ai-center muted-text">
                                    <h6 class="p-m-0">{{ financialActive ? $t('reports.payoutsFinancial') : $t('reports.payoutsManagement') }}</h6>
                                </div>
                                <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div class="p-d-flex p-flex-column">
                                        <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.Payouts) + ' ' + $t('general.currency') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6 p-md-3">
                            <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div class="p-d-flex p-ai-center muted-text">
                                    <h6 class="p-m-0">{{ financialActive ? $t('reports.profitFinancial') : $t('reports.profitManagement') }}</h6>
                                </div>
                                <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div class="p-d-flex p-flex-column">
                                        <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.Profit) + ' ' + $t('general.currency') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6 p-md-3">
                            <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div class="p-d-flex p-ai-center muted-text">
                                    <h6 class="p-m-0">{{ financialActive ? $t('reports.profitFinancial') + '%' : $t('reports.profitManagement') + '%' }}</h6>
                                </div>
                                <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div class="p-d-flex p-flex-column">
                                        <span class="p-mb-1 fs-xlarge">{{ parseFloat(totalRow.ProfitPerc).toFixed(2) + ' %' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <span class="p-input-icon-right">
                                <InputText type="text" :placeholder="$t('system.shop')" v-model="searchTerm" @input="debounceSearch" />
                                <i class="pi pi-search" />
                            </span>
                        </div>
                    </div>
                    <DataTable @page="onPage($event)" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" :value="filteredData">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('system.code')" field="ShopCode" :sortable="true"> </Column>
                        <Column :header="$t('system.shop')" field="ShopName" :sortable="true"> </Column>
                        <Column field="Incomes" :header="$t('reports.incomes')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.Incomes) }} </template></Column
                        >
                        <Column field="Payouts" :sortable="true" :header="financialActive ? $t('reports.payoutsFinancial') : $t('reports.payoutsManagement')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.Payouts) }} </template></Column
                        >
                        <Column field="Profit" :sortable="true" :header="financialActive ? $t('reports.profitFinancial') : $t('reports.profitManagement')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.Profit) }} </template></Column
                        >
                        <Column field="ProfitPerc" :sortable="true" :header="financialActive ? $t('reports.profitFinancial') + '%' : $t('reports.profitManagement') + '%'"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoApi } from '../../../service/BoApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import settings from '../../../settings/generalSettings';
import { uiSettings } from '../../../settings/uiSettings';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            term: '',
            searchTerm: '',
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
            productModel: { id: 'ALL', name: 'All' },
            reportTypeModel: 'management',
            perPage: uiSettings.tableRows,
            crtPage: 1,
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.reportTypeModel = this.reportTypes[0];
    },
    computed: {
        products() {
            return [
                {
                    id: settings.products.all,
                    name: this.$t('products.all'),
                },
                {
                    id: settings.products.sportsBetting,
                    name: this.$t('products.prematch'),
                },
                {
                    id: settings.products.live,
                    name: this.$t('products.live'),
                },
                {
                    id: settings.products.keno,
                    name: this.$t('products.keno'),
                },
                {
                    id: settings.products.ballsix,
                    name: this.$t('products.ballsix'),
                },
                {
                    id: settings.products.roulette,
                    name: this.$t('products.roulette'),
                },
                {
                    id: settings.products.rocket,
                    name: this.$t('products.rocket'),
                },
                {
                    id: settings.products.lotto,
                    name: this.$t('products.lotto'),
                },
                {
                    id: settings.products.nsoft,
                    name: this.$t('products.nsoft'),
                    channel: [settings.productChannels.all, settings.productChannels.retail],
                },
                {
                    id: settings.products.terminal,
                    name: this.$t('products.terminal'),
                },
            ];
        },
        reportTypes() {
            return [
                { id: 'management', name: this.$t('reports.managementData') },
                { id: 'financial', name: this.$t('reports.financialData') },
            ];
        },
        financialActive() {
            return this.reportTypeModel.id == 'financial';
        },
        filteredData() {
            if (this.notNullOrEmptyField(this.term)) {
                return this.reportData.filter((item) => {
                    return item.ShopName.toLowerCase().startsWith(this.term.toLowerCase());
                });
            } else {
                return this.reportData;
            }
        },
    },
    watch: {
        reportTypeModel() {
            this.showReportData = false;
        },
    },
    methods: {
        showReport() {
            this.showReportData = false;
            this.isReportLoading = true;
            if (this.financialActive) {
                this.BoReportsApi.getFinancialReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.productModel.id)
                    .then((reportResponse) => {
                        if (reportResponse.data != null) {
                            this.reportData = reportResponse.data.ReportData;
                            this.totalRow = reportResponse.data.TotalRow;
                            this.tableKey++;
                        }
                        this.isReportLoading = false;
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportLoading = false;
                    });
            } else {
                this.BoReportsApi.getManagementReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.productModel.id)
                    .then((reportResponse) => {
                        if (reportResponse.data != null) {
                            this.reportData = reportResponse.data.ReportData;
                            this.totalRow = reportResponse.data.TotalRow;
                            this.tableKey++;
                        }
                        this.isReportLoading = false;
                        this.showReportData = true;
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportLoading = false;
                    });
            }
        },
        saveXls() {
            this.isReportDownloading = true;
            if (this.financialActive) {
                this.BoSaveReportsApi.saveFinancialReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.productModel.id)
                    .then((response) => {
                        this.isReportDownloading = false;
                        let blob = new Blob([response.data], { type: 'application/xls' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'P&L_Financial' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportDownloading = false;
                    });
            } else {
                this.BoSaveReportsApi.saveManagementReport(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.productModel.id)
                    .then((response) => {
                        this.isReportDownloading = false;
                        let blob = new Blob([response.data], { type: 'application/xls' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'P&L_Management' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                        link.click();
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorKeyCount++;
                        this.isReportDownloading = false;
                    });
            }
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>